<template>
  <loading v-if="loading" />

  <div v-else>
    <div class="columns is-marginless">
      <div class="column is-6">
        <component :is="'p'" class="has-white-space-pre-line">{{
          order.billingEntity.address
        }}</component>
      </div>

      <div class="column is-6 is-12-mobile has-text-right has-text-left-mobile">
        <p class="has-margin-bottom-25">
          <strong>Order #{{ order.orderNum }}</strong>
        </p>

        <p class="has-margin-bottom-25">
          <router-link :to="`/admin/invoices/${order.invoiceRef.id}`">
            <u>View invoice</u>
          </router-link>
        </p>

        <p class="has-margin-bottom-25">{{ user.email }}</p>

        <p class="has-margin-bottom-25">
          Issued
          {{ $moment(order.dateCreated.toDate()).format("Do MMM, YYYY") }}
        </p>

        <p class="has-margin-bottom-25">
          Paid {{ $moment(order.datePaid.toDate()).format("Do MMM, YYYY") }}
        </p>

        <p>Currency {{ order.currency.toUpperCase() }}</p>
      </div>
    </div>

    <table class="table table-full-width has-margin-bottom-100">
      <thead>
        <tr>
          <th colspan="2">Items</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in order.items" :key="item.description">
          <td>{{ item.description }}</td>
          <td style="width:1%;">
            {{ $formatCurrency(item.total, order.currency) }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="columns is-marginless">
      <div class="column is-5 is-offset-7 is-12-mobile">
        <div class="totals">
          <apart v-for="summary in totalsSummary" :key="summary.label">
            <span>
              {{ summary.label }}
              <b-tooltip
                v-if="summary.tooltip"
                :label="summary.tooltip"
                type="is-info"
                multilined
              >
                <b-icon icon="info-circle" size="is-small" type="is-info" />
              </b-tooltip>
            </span>

            <strong>{{ summary.value }}</strong>
          </apart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Order",
  props: {
    orderId: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    order() {
      return this.$store.getters["orders/order"](this.orderId);
    },
    user() {
      return this.$store.getters["user/user"](
        this.$_.get(this.order, "userId")
      );
    },
    totalsSummary() {
      const summary = [],
        { tax } = this.order;
      if (!!tax.jurisdiction || !!tax.total) {
        summary.push({
          label: `Subtotal`,
          value: this.$formatCurrency(this.order.subtotal, this.order.currency)
        });
        summary.push({
          label: `Tax at ${tax.rate}%`,
          value: `${this.$formatCurrency(
            this.order.tax.total,
            this.order.currency
          )}`,
          tooltip: `As your card is registered to an address within ${tax.country}, ${this.order.tax.rate}% VAT has been applied to your order.`
        });
      }
      summary.push({
        label: `Total`,
        value: this.$formatCurrency(this.order.total, this.order.currency),
        class: "has-text-info"
      });
      return summary;
    }
  },
  watch: {
    order(data) {
      if (this.$_.has(data, "userId")) {
        this.orderLoaded();
      }
    }
  },
  created() {
    this.$store.dispatch("orders/observeOrder", { orderId: this.orderId });
  },
  beforeDestroy() {
    this.$store.dispatch("user/unobserveUser", { userId: this.order.userId });
    this.$store.dispatch("orders/unobserveOrder", { orderId: this.orderId });
  },
  methods: {
    orderLoaded() {
      this.loading = false;
      this.$store.dispatch("user/observeUser", {
        userId: this.order.userId
      });
    }
  }
};
</script>
